import { SelectionChangedEvent } from "ag-grid-community";
import { CostCenterCodeMappingEntity } from "domain/entity/CostCenterCodeMapping/CostCenterCodeMappingEntity";
import { INITIAL_COST_CENTER_CODE_MAPPING_COL_DEF } from "presentation/constant/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceColumnDefinition";
import { CostCenterCodeMappingMaintenanceConstant } from "presentation/constant/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceConstant";
import { useCostCenterCodeMappingMaintenanceVM } from "presentation/hook/CostCenterCodeMapping/useCostCenterCodeMappingMaintenanceVM";
import { useCostCenterCodeMappingMaintenanceTracked } from "presentation/store/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const CostCenterCodeMappingTablePanel:React.FC = () => {
    const [costCenterCodeMappingState] = useCostCenterCodeMappingMaintenanceTracked();
    const costCenterCodeMappingVM = useCostCenterCodeMappingMaintenanceVM();
    const gridRef: any = useRef(null);
    // const rightClickRef: any = createRef();
    const {tableData} = costCenterCodeMappingState;
    // const [anainfoState] = useANAInfoTracked();
    // const {allowUpdate} = anainfoState;
    // const messageBarVM = useMessageBarVM();   

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {     
        const selectedRows = e.api.getSelectedRows();

        costCenterCodeMappingVM.updateSelectedRows(tableData,selectedRows);
    }, [costCenterCodeMappingVM, tableData])

    const handleAddClick = useCallback(() => {
        costCenterCodeMappingVM.onAdd();
    }, [costCenterCodeMappingVM])

    const handleRowDoubleClick = useCallback((entity: CostCenterCodeMappingEntity) => {
          costCenterCodeMappingVM.onEdit(entity);
    }, [costCenterCodeMappingVM])

    const memoTable = useMemo(() => {
        return (
        <HPHTable
            id='cost-center-code-mapping-table'
            headerLabel={CostCenterCodeMappingMaintenanceConstant.TITLE}
            isNewColumnSetting={true}
            columns={INITIAL_COST_CENTER_CODE_MAPPING_COL_DEF}
            data={tableData??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={true}
            onAddClick={handleAddClick}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: CostCenterCodeMappingEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            />
        );
    },[tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
            {memoTable}
        </TableWrapper>
        </>;
}

export default memo(CostCenterCodeMappingTablePanel);
