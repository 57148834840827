
import { CostCenterCodeMappingEntity, EMPTY_COST_CENTER_CODE_MAPPING_ENTITY } from "domain/entity/CostCenterCodeMapping/CostCenterCodeMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface CostCenterCodeMappingDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: {[key: string]: DropdownProps[]};
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: {[key: string]: DropdownProps[]};
    companyCodeDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: CostCenterCodeMappingEntity,
}

export interface CostCenterCodeMappingMaintenanceModel {
    isLoading: boolean,
    tableData: CostCenterCodeMappingEntity[],
    currentSelectedRow: CostCenterCodeMappingEntity,
    selectedRows: CostCenterCodeMappingEntity[],
    dynamicOptions: CostCenterCodeMappingDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
}

export const EMPTY_MASTER_COST_CENTER_CODE_MAPPINGANY_MODEL: CostCenterCodeMappingMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_COST_CENTER_CODE_MAPPING_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        companyCodeDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_COST_CENTER_CODE_MAPPING_ENTITY,
    },
    isBackMaster: false,
}