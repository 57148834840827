import { CostCenterCodeMappingMaintenanceConstant } from "presentation/constant/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceConstant";
import { useCostCenterCodeMappingMaintenanceVM } from "presentation/hook/CostCenterCodeMapping/useCostCenterCodeMappingMaintenanceVM";
import { useCostCenterCodeMappingMaintenanceTracked } from "presentation/store/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CostCenterCodeMappingEditPanelTitleBar from "./CostCenterCodeMappingEditPanelTitleBar";

export const CostCenterCodeMappingMaintenanceFormPanel = () => {
    const [costCenterCodeMappingState] = useCostCenterCodeMappingMaintenanceTracked();
    const costCenterCodeMappingVM = useCostCenterCodeMappingMaintenanceVM();
    const { currentSelectedRow, masterState, dynamicOptions } = costCenterCodeMappingState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const SCREEN_CONSTANT = CostCenterCodeMappingMaintenanceConstant;


    const currentEntity = isRead ? currentSelectedRow : editingEntity;


    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEntity.chargeType ? dynamicOptions.subChargeTypeDropdownOptions[currentEntity.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEntity.chargeType, dynamicOptions.subChargeTypeDropdownOptions])

    
    const memoTarCodeOptions = useMemo(() => {
        const tarCodeOptions = currentEntity.tariffType ? dynamicOptions.tariffCodeDropdownOptions[currentEntity.tariffType] : [];
        return tarCodeOptions;
    }, [currentEntity.tariffType, dynamicOptions.tariffCodeDropdownOptions])


    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.chargeType || ''}
                fieldValue={currentEntity?.chargeType}
                fieldLabel={SCREEN_CONSTANT.CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    costCenterCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.chargeType, SCREEN_CONSTANT.CHARGE_TYPE, isSaveClicked, dynamicOptions.chargeTypeDropdownOptions, costCenterCodeMappingVM])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.subChargeType || ''}
                fieldValue={currentEntity?.subChargeType || ''}
                fieldLabel={SCREEN_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                isShowMissingError={true}
                maxLength={10}
                options={memoSubChgTypeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    costCenterCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.subChargeType, SCREEN_CONSTANT.SUB_CHARGE_TYPE, isSaveClicked, memoSubChgTypeOptions, costCenterCodeMappingVM])

    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffType || ''}
                fieldValue={currentEntity?.tariffType || ''}
                fieldLabel={SCREEN_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    costCenterCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.tariffType, SCREEN_CONSTANT.TARIFF_TYPE, isSaveClicked, dynamicOptions.tariffTypeDropdownOptions, costCenterCodeMappingVM])


    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffCode || ''}
                fieldValue={currentEntity?.tariffCode || ''}
                fieldLabel={SCREEN_CONSTANT.TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                isShowMissingError={true}
                maxLength={10}
                options={memoTarCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    costCenterCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.tariffCode, SCREEN_CONSTANT.TARIFF_CODE, isSaveClicked, memoTarCodeOptions, costCenterCodeMappingVM])

    const memoChargeOn = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.chargeOnCompany || ''}
                fieldValue={currentEntity?.chargeOnCompany || ''}
                fieldLabel={SCREEN_CONSTANT.CHARGEON_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    costCenterCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.chargeOnCompany, SCREEN_CONSTANT.CHARGEON_COMPANY, isSaveClicked, dynamicOptions.companyCodeDropdownOptions, costCenterCodeMappingVM])

    const memoBillTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.billToCompany || ''}
                fieldValue={currentEntity?.billToCompany || ''}
                fieldLabel={SCREEN_CONSTANT.BILLTO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    costCenterCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.billToCompany, SCREEN_CONSTANT.BILLTO_COMPANY, isSaveClicked, dynamicOptions.companyCodeDropdownOptions, costCenterCodeMappingVM])

    const memoCostCenterCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.costCenterCode || ''}
                fieldValue={currentEntity?.costCenterCode || ''}
                fieldLabel={SCREEN_CONSTANT.COST_CENTER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'costCenterCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    costCenterCodeMappingVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.costCenterCode, SCREEN_CONSTANT.COST_CENTER_CODE, isSaveClicked, costCenterCodeMappingVM])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => costCenterCodeMappingVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, costCenterCodeMappingVM])


    const memoEditPanelTitleBar = useMemo(() => {
        return <CostCenterCodeMappingEditPanelTitleBar />
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{!isAdd ? currentEntity.costCenterCode : "NEW CODE"}</SidebarTitle>

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoChargeType}{memoSubChargeType}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                    {memoTarType}{memoTarCode}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                    {memoChargeOn}{memoBillTo}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                    {memoCostCenterCode} {memoActiveInd}
                    </CriteriaItemContainer>

                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
