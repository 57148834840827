
import { useCostCenterCodeMappingMaintenanceTracked } from "presentation/store/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import CostCenterCodeMappingMaintenance from "./CostCenterCodeMappingMaintenance";
import { CostCenterCodeMappingMaintenanceFormPanel } from "./CostCenterCodeMappingMaintenanceFormPanel";

export const CostCenterCodeMappingMasterView: React.FC = () => {
    const [costCenterCodeMappingState] = useCostCenterCodeMappingMaintenanceTracked();
    const { isAdd, isEditable } = costCenterCodeMappingState.masterState;

    return <>
        <div className="main-comp-wrapper">
            <CostCenterCodeMappingMaintenance/>
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<CostCenterCodeMappingMaintenanceFormPanel/>}
                />
                )
            }
        </div>
    </>
}